<template>
  <div>
    <b-card
      no-body
      class="liveinfo-form"
    >
      <div class="m-2">
        <b-row>
          <b-col
            md="9"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <p class="h5 mt-50 mr-75 ml-50">
              조회 타입
            </p>
            <b-form-radio-group
              v-model="query.isPrefixSum"
            >
              <b-form-radio :value="true">
                누적
              </b-form-radio>
              <b-form-radio :value="false">
                일별
              </b-form-radio>
            </b-form-radio-group>
            <p class="h5 mt-50 mr-75 ml-50">
              조회 기준
            </p>
            <b-form-radio-group
              v-model="query.countingGroup"
            >
              <b-form-radio :value="2">
                의원
              </b-form-radio>
              <b-form-radio :value="1">
                의사
                <b-badge
                  v-b-tooltip.hover.v-primary
                  variant="light-primary"
                  style="padding: 0px; "
                  title="의사 기준으로 조회 시 수집기준에 따른 기등록수/노출수/클릭수 제외한 기타 항목은 의원과 동일"
                >
                  <feather-icon
                    icon="AlertCircleIcon"
                  />
                </b-badge>
              </b-form-radio>

            </b-form-radio-group>
            <b-form-datepicker
              v-model="query.searchStartDate"
              class="el-def"
              placeholder="게시시작일(Fr)"
              style="width: 250px"
            />
            <label class="ml-25 mr-50">~</label>
            <b-form-datepicker
              v-model="query.searchEndDate"
              class="el-def"
              placeholder="게시시작일(To)"
              style="width: 250px"
            />
            <label style="width: 140px">(*당일데이터 조회 불가)</label>
          </b-col>
          <b-col
            md="3"
            class="d-flex align-items-center justify-content-end"
          >
            <b-button
              variant="primary"
              class="btn-search"
              @click.prevent="fetchData()"
            >
              조회
            </b-button>
            <b-button
              variant="outline-dark"
              class="btn-search ml-1"
              @click.prevent="excelDownload()"
            >
              <feather-icon
                icon="DownloadIcon"
                class="mr-50"
              />
              <span>엑셀 다운로드</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <b-card
      no-body
      class="liveinfo-form"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="11"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-1"
          >

            <v-select
              v-model="query.clientId"
              :options="codes.clients"
              placeholder="제약사"
              :reduce="option => option.code"
              class="el-def"
              style="width:20%"
            />

            <v-select
              v-model="query.postStatusType"
              :options="codes.postStatusType"
              class="invoice-filter-select el-def"
              :reduce="(option) => option.code"
              placeholder="게시상태"
            />

            <b-form-input
              v-model="query.drugName"
              class="el-def"
              placeholder="제품명"
              style="width: 60%;"
            />

          </b-col>
          <b-col
            cols="1"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-1"
          >

            <b-button
              variant="outline-secondary"
              class="btn-search"
              @click.prevent="resetData()"
            >
              초기화
            </b-button>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="10">
            <b-form-group
              label="지역"
              label-for="liveinfo-cond-region"
            >
              <b-form-input
                id="liveinfo-cond-region"
                v-model="query.regionName"
                readonly
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="2"
            class="mb-50"
          >
            <b-button
              variant="outline-primary"
              class="mt-0 mt-md-2"
              @click.prevent="showRegionSelect = true"
            >
              <feather-icon
                icon="PlusSquareIcon"
                class="mr-50"
              />
              <span>목록</span>
            </b-button>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="10">
            <b-form-group
              label="진료과"
              label-for="liveinfo-cond-dept"
            >
              <b-form-input
                id="liveinfo-cond-dept"
                v-model="query.medicalDeptNames"
                readonly
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="2"
            class="mb-50"
          >
            <b-button
              variant="outline-primary"
              class="mt-0 mt-md-2"
              @click.prevent="showMediDeptSelect = true"
            >
              <feather-icon
                icon="PlusSquareIcon"
                class="mr-50"
              />
              <span>목록</span>
            </b-button>
          </b-col>
        </b-row>

      </div>
    </b-card>

    <b-card>
      <cumulative-grid
        v-if="isPrefixSum"
        :data="drugLandingData"
        :search-type="countingGroup"
      />

      <daily-grid
        v-else-if="!isPrefixSum"
        :data="drugLandingData"
        :search-type="countingGroup"
      />
    </b-card>

    <region-select
      :show-region-select="showRegionSelect"
      :selected-value="query.regions"
      @close="regionSelect.close"
    />

    <medi-dept-select
      :show-medi-dept-select="showMediDeptSelect"
      :selected-value="query.medicalDepts"
      @close="mediDeptSelect.close"
    />
  </div>
</template>

<script>
import axios from '@axios'
import { onMounted, ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import dayjs from 'dayjs'
import ExcelJS from "exceljs"
import { saveAs } from "file-saver"
import vSelect from "vue-select"
import {
  BFormInput, BFormGroup, BRow, BCol, BButton, BBadge, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import RegionSelect from '@/views/apps/popup/RegionSelect.vue'
import MediDeptSelect from '@/views/apps/popup/MediDeptSelect.vue'
import { arrayJoinStr } from '@core/utils/filter'
import CumulativeGrid from './grid/CumulativeGrid.vue'
import DailyGrid from './grid/DailyGrid.vue'

export default {
  components: {
    vSelect,
    CumulativeGrid,
    DailyGrid,
    RegionSelect,
    MediDeptSelect,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BButton,
    BBadge,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  setup() {
    onMounted(() => {
      fetchCodes()
    })
    const codes = ref({
      regions: [],
      postStatusType: [],
      clients: [],
    })

    const fetchCodes = () => {
      axios.get("/fa/druglanding/codes")
        .then(rs => {
          const { hcpRegion, postStatusType, clients } = rs.data

          codes.value.regions = [{ code: 0, label: '전체' }, ...hcpRegion]
          codes.value.postStatusType = postStatusType
          codes.value.clients = clients
          // fetchData()
        })
        .catch(() => {
          pushToast('danger', '코드 데이터를 불러오는데 실패하였습니다.')
        })
    }

    const showRegionSelect = ref(false)
    const regionSelect = {
      close: rs => {
        showRegionSelect.value = false

        if (rs) {
          query.value.regions = arrayJoinStr(rs, 'code')
          query.value.regionName = arrayJoinStr(rs, 'label')
        }
      },
    }

    const showMediDeptSelect = ref(false)
    const mediDeptSelect = {
      close: rs => {
        showMediDeptSelect.value = false

        if (rs) {
          query.value.medicalDepts = arrayJoinStr(rs, 'code')
          query.value.medicalDeptNames = arrayJoinStr(rs, 'label')
        }
      },
    }

    const toast = useToast()
    const pushToast = (variant, title) => {
      let icon

      if (variant === 'success') {
        icon = 'CheckCircleIcon'
      } else {
        icon = 'AlertTriangleIcon'
      }

      toast({
        component: ToastificationContent,
        props: { title, icon, variant },
      })
    }

    const query = ref({
      searchStartDate: dayjs().add(-1, 'month').format('YYYY-MM-DD'),
      searchEndDate: dayjs().add(-1, 'day').format('YYYY-MM-DD'),
      countingGroup: 2,
      clientId: null,
      regions: null,
      regionName: null,
      postStatusType: null,
      medicalDepts: null,
      medicalDeptNames: '',
      drugName: null,
      isPrefixSum: true,
    })

    const countingGroup = ref(2)
    const isPrefixSum = ref(true)
    const drugLandingData = ref([])

    const resetData = () => {
      // 상세 검색조건 초기화
      query.value.clientId = null
      query.value.regions = null
      query.value.regionName = null
      query.value.postStatusType = null
      query.value.drugName = null
      query.value.medicalDepts = null
      query.value.medicalDeptNames = ''
    }

    const fetchData = () => {
      const frDate = dayjs(query.value.searchStartDate)
      const toDate = dayjs(query.value.searchEndDate)

      // // 조회 기간 제한 (해제)
      // if (toDate.diff(frDate, 'day') > 90) {
      //   pushToast('warning', '조회 기간은 최대 90일을 넘을 수 없습니다.')
      //   return
      // }

      // 종료일이 시작일보다 빠를 수 없음
      if (toDate.diff(frDate, 'day') < 0) {
        pushToast('warning', '종료일이 시작일보다 앞설 수 없습니다.')
        return
      }

      axios.post('/fa/statistics-ext/drug-landing', {
        startDate: query.value.searchStartDate,
        endDate: query.value.searchEndDate,
        countingGroup: query.value.countingGroup,
        clientId: query.value.clientId,
        // region: query.value.region,
        postStatusType: query.value.postStatusType,
        medicalDeptsStr: query.value.medicalDepts,
        regionsStr: query.value.regions,
        drugName: query.value.drugName,
        isPrefixSum: query.value.isPrefixSum,
      })
        .then(rs => {
          isPrefixSum.value = query.value.isPrefixSum
          countingGroup.value = query.value.countingGroup

          drugLandingData.value = rs.data
        })
        .catch(err => {
          toast({
            component: ToastificationContent,
            props: {
              title: `데이터 조회 중 오류가 발생하였습니다.${err}`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const excelDownload = async () => {
      if (isPrefixSum.value) cumulativeDownload()
      else dailyDownload()
    }

    const cumulativeDownload = async () => {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet("sheet1")

      worksheet.columns = [
        { header: "지역", key: "regionName", width: 15 },
        { header: "ID", key: "liveInfoId", width: 15 },
        { header: "제약사", key: "clientName", width: 25 },
        { header: "제품명", key: "drugName", width: 50 },
        { header: "청구코드", key: "cgCode", width: 25 },
        { header: "게시 기간", key: "liveDate", width: 25 },
        { header: "진료과", key: "medicalDeptName", width: 20 },
        {
          header: countingGroup.value === 2 ? "전체처수" : "전체수", key: "totalRegCount", width: 15, style: { numFmt: '#,##0' },
        },
        {
          header: countingGroup.value === 2 ? "요양기관수" : "의사수", key: "uniqueCount", width: 15, style: { numFmt: '#,##0' },
        },
        {
          header: countingGroup.value === 2 ? "기등록처수" : "기등록수", key: "wasRegCount", width: 15, style: { numFmt: '#,##0' },
        },
        {
          header: countingGroup.value === 2 ? "노출처수" : "노출수", key: "unRegCount", width: 15, style: { numFmt: '#,##0' },
        },
        {
          header: "클릭수", key: "totalClickCount", width: 15, style: { numFmt: '#,##0' },
        },
        {
          header: "클릭수(중복제거)", key: "groupClickCount", width: 15, style: { numFmt: '#,##0' },
        },
        {
          header: countingGroup.value === 2 ? "등록처수(모아보기)" : "등록수(모아보기)", key: "landingRegCount", width: 15, style: { numFmt: '#,##0' },
        },
        {
          header: countingGroup.value === 2 ? "등록처수(기초자료)" : "등록수(기초자료)", key: "ysrRegCount", width: 15, style: { numFmt: '#,##0' },
        },
        {
          header: countingGroup.value === 2 ? "잔여처수" : "잔여수", key: "remainCount", width: 15, style: { numFmt: '#,##0' },
        },
        {
          header: "게시 잔여기간", key: "remainDate", width: 15, style: { numFmt: '#,##0' },
        },
      ]

      let startRow = 2
      let endRow = startRow

      // eslint-disable-next-line array-callback-return
      drugLandingData.value.map(e => {
        e.landingDepts.map(x => worksheet.addRow({
          regionName: e.regionName,
          liveInfoId: e.liveInfoId,
          clientName: e.clientName,
          drugName: e.drugName,
          cgCode: e.cgCode,
          liveDate: `${dayjs(e.startDate).format('YYYY-MM-DD')} ~ ${dayjs(e.endDate).format('YYYY-MM-DD')}`,
          medicalDeptName: x.medicalDeptName,
          totalRegCount: x.wasRegCount + x.unRegCount,
          uniqueCount: x.uniqueCount,
          wasRegCount: x.wasRegCount,
          unRegCount: x.unRegCount,
          totalClickCount: x.totalClickCount,
          groupClickCount: x.groupClickCount,
          landingRegCount: x.landingRegCount,
          ysrRegCount: x.ysrRegCount,
          remainCount: x.unRegCount - (x.landingRegCount + x.ysrRegCount),
          remainDate: `${getRemainDate(e.endDateStr)}일`,
        }))

        endRow += e.landingDepts.length - 1

        worksheet.mergeCells(`A${startRow}:A${endRow}`)
        worksheet.mergeCells(`B${startRow}:B${endRow}`)
        worksheet.mergeCells(`C${startRow}:C${endRow}`)
        worksheet.mergeCells(`D${startRow}:D${endRow}`)
        worksheet.mergeCells(`E${startRow}:E${endRow}`)
        worksheet.mergeCells(`Q${startRow}:Q${endRow}`)

        startRow = endRow + 1
        endRow = startRow
      })

      worksheet.eachRow({ includeEmpty: true }, (row, number) => {
        row.eachCell({ includeEmpty: true }, cell => {
          if (number === 1) {
            // eslint-disable-next-line no-param-reassign
            cell.font = {
              bold: true,
            }

            // eslint-disable-next-line no-param-reassign
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'D3D3D3' },
            }
          }

          // eslint-disable-next-line no-param-reassign
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          }

          if (cell.value === '소계' || cell.value === '합계') {
            // eslint-disable-next-line no-param-reassign
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'center',
            }
          } else {
            // eslint-disable-next-line no-param-reassign
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'left',
            }
          }
        })
      })

      const mimeType = {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      }

      const buffer = await workbook.xlsx.writeBuffer()

      const blob = new Blob([buffer], mimeType)

      saveAs(blob, "간편처방등록 통계(누적).xlsx")
    }

    const dailyDownload = async () => {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet("sheet1")

      worksheet.columns = [
        { header: "지역", key: "regionName", width: 15 },
        { header: "ID", key: "liveInfoId", width: 15 },
        { header: "제약사", key: "clientName", width: 25 },
        { header: "제품명", key: "drugName", width: 50 },
        { header: "청구코드", key: "cgCode", width: 25 },
        { header: "게시 기간", key: "execDate", width: 25 },
        { header: "진료과", key: "medicalDeptName", width: 20 },
        {
          header: countingGroup.value === 2 ? "전체처수" : "전체수", key: "totalRegCount", width: 15, style: { numFmt: '#,##0' },
        },
        {
          header: countingGroup.value === 2 ? "요양기관수" : "의사수", key: "uniqueCount", width: 15, style: { numFmt: '#,##0' },
        },
        {
          header: countingGroup.value === 2 ? "기등록처수" : "기등록수", key: "wasRegCount", width: 15, style: { numFmt: '#,##0' },
        },
        {
          header: countingGroup.value === 2 ? "노출처수" : "노출수", key: "unRegCount", width: 15, style: { numFmt: '#,##0' },
        },
        {
          header: "클릭수", key: "totalClickCount", width: 15, style: { numFmt: '#,##0' },
        },
        {
          header: "클릭수(중복제거)", key: "groupClickCount", width: 15, style: { numFmt: '#,##0' },
        },
        {
          header: countingGroup.value === 2 ? "등록처수(모아보기)" : "등록수(모아보기)", key: "landingRegCount", width: 15, style: { numFmt: '#,##0' },
        },
        {
          header: countingGroup.value === 2 ? "등록처수(기초자료)" : "등록수(기초자료)", key: "ysrRegCount", width: 15, style: { numFmt: '#,##0' },
        },
        {
          header: countingGroup.value === 2 ? "잔여처수" : "잔여수", key: "remainCount", width: 15, style: { numFmt: '#,##0' },
        },
        {
          header: "게시 잔여기간", key: "remainDate", width: 15, style: { numFmt: '#,##0' },
        },
      ]

      let startRow = 2
      let endRow = startRow

      // eslint-disable-next-line array-callback-return
      drugLandingData.value.map(e => {
        e.landingDepts.map(x => worksheet.addRow({
          regionName: e.regionName,
          liveInfoId: e.liveInfoId,
          clientName: e.clientName,
          drugName: e.drugName,
          cgCode: e.cgCode,
          execDate: `${dayjs(e.execDate).format('YYYY-MM-DD')}`,
          medicalDeptName: x.medicalDeptName,
          totalRegCount: x.wasRegCount + x.unRegCount,
          uniqueCount: x.uniqueCount,
          wasRegCount: x.wasRegCount,
          unRegCount: x.unRegCount,
          totalClickCount: x.totalClickCount,
          groupClickCount: x.groupClickCount,
          landingRegCount: x.landingRegCount,
          ysrRegCount: x.ysrRegCount,
          remainCount: x.unRegCount - (x.landingRegCount + x.ysrRegCount),
          remainDate: `${getRemainDate(e.endDateStr)}일`,
        }))

        endRow += e.landingDepts.length - 1

        worksheet.mergeCells(`A${startRow}:A${endRow}`)
        worksheet.mergeCells(`B${startRow}:B${endRow}`)
        worksheet.mergeCells(`C${startRow}:C${endRow}`)
        worksheet.mergeCells(`D${startRow}:D${endRow}`)
        worksheet.mergeCells(`E${startRow}:E${endRow}`)
        worksheet.mergeCells(`F${startRow}:F${endRow}`)
        worksheet.mergeCells(`Q${startRow}:Q${endRow}`)

        startRow = endRow + 1
        endRow = startRow
      })

      worksheet.eachRow({ includeEmpty: true }, (row, number) => {
        row.eachCell({ includeEmpty: true }, cell => {
          if (number === 1) {
            // eslint-disable-next-line no-param-reassign
            cell.font = {
              bold: true,
            }

            // eslint-disable-next-line no-param-reassign
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'D3D3D3' },
            }
          }

          // eslint-disable-next-line no-param-reassign
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          }

          if (cell.value === '소계' || cell.value === '합계') {
            // eslint-disable-next-line no-param-reassign
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'center',
            }
          } else {
            // eslint-disable-next-line no-param-reassign
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'left',
            }
          }
        })
      })

      const mimeType = {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      }

      const buffer = await workbook.xlsx.writeBuffer()

      const blob = new Blob([buffer], mimeType)

      saveAs(blob, "간편처방등록 통계(일별).xlsx")
    }

    const getRemainDate = toDateStr => {
      const todayDate = dayjs()
      const result = dayjs(toDateStr).diff(todayDate, 'day') + 1
      if (result > 0) { return result }
      return 0
    }

    return {
      codes,
      fetchCodes,
      query,
      countingGroup,
      isPrefixSum,
      drugLandingData,
      resetData,
      fetchData,
      excelDownload,
      cumulativeDownload,
      dailyDownload,
      regionSelect,
      mediDeptSelect,
      showRegionSelect,
      showMediDeptSelect,
      getRemainDate,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.dropdown-menu {
  z-index: 100 !important;
}

.tooltip .tooltip-inner{
  max-width: 480px !important;
}
</style>
